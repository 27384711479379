import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // (查詢)標籤列表
    getTagList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/tag', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)標籤
    setTagCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/tag', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)標籤
    setTagUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/tag/${resolveData.tag_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)標籤
    setTagDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/tag/${resolveData.tag_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
